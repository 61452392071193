.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.jsonView {
  background-color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Custom Sidebar Styling **/
.SideNav {
  list-style-type: none;
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  outline: none;
  height: 100%;
}

.SideNav-item {
  list-style-type: none;
  margin: var(--spectrum-global-dimension-size-50) 0;
}

.SideNav-itemLink {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  width: 100%;
  min-height: var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400));
  padding: var(--spectrum-global-dimension-size-65) var(--spectrum-global-dimension-size-150);
  border-radius: var(--spectrum-alias-border-radius-regular, var(--spectrum-global-dimension-size-50));
  font-size: var(--spectrum-alias-font-size-default, var(--spectrum-global-dimension-font-size-100));
  font-weight: var(--spectrum-global-font-weight-regular, 400);
  font-style: normal;
  text-decoration: none;
  word-break: break-word;
  cursor: pointer;
  background-color: var(--spectrum-alias-background-color-transparent,transparent);
  color: var(--spectrum-alias-text-color,var(--spectrum-global-color-gray-800));
}

.SideNav-itemLink.is-selected {
  color: var(--spectrum-alias-text-color-hover,var(--spectrum-global-color-gray-900));
  background-color: var(--spectrum-alias-highlight-hover);
}


.form-container {
  margin: 20px;
}